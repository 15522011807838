@media (max-width: 1100px) {
  .box-ticket-picker {
    .picker-section {
      .content {
        table {
          thead {
            display:none;
          }
          tbody {
            tr {
              td {
                &[data-th] {
                  &:before {
                    line-height: normal;
                    content: attr(data-th) ":";
                    width: 100%;
                    padding: 0;
                    text-align: left;
                    font-weight: bold;
                    display: block;
                  }
                }

                &:last-child {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}